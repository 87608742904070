import { FC, useEffect, useState } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getMyAccountStudioRequest, updateMyAccountStudioRequest } from '../../actions';

// Plugins
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import { PatternFormat } from 'react-number-format';

// Components
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';
import BusinessVerificationModal from '@/components/Shared/BusinessVerification/VerificationModal';

// Helpers
import { shortDate } from '@/utils/displayFormats';

// Types
import { StudioVerificationStatus, StudioCertificationStatus, StudioSellerType, StudioVerificationStage } from '@/types';

const StudioVerification: FC = () => {
  const dispatch = useDispatch();

  const { studio: loginStudio } = useSelector((state: any) => state.login);
  const { studio: usersStudio, requesting: usersRequesting } = useSelector((state: any) => state.users);

  const {
    id: studioId,
    email: studioContactEmail,
    phone: studioContactPhone,
    verification_required: studioVerificationRequired,
    verification_status: studioVerificationStatus,
    studio_verification: studioVerificationAttributes
  } = Object.keys(usersStudio ?? {}).length ? usersStudio : loginStudio;

  const [showBusinessVerificationModal, setShowBusinessVerificationModal] = useState<boolean>(false);
  const [showUpdateVerificationModal, setShowUpdateVerificationModal] = useState<boolean>(false);
  const [showCertifyInformationModal, setShowCertifyInformationModal] = useState<boolean>(false);

  const [studioVerificationStage, setStudioVerificationStage] = useState<StudioVerificationStage>(StudioVerificationStage.Verification);

  // UI Handlers
  const handleGetVerified = (): void => {
    setStudioVerificationStage(StudioVerificationStage.Verification);
    setShowBusinessVerificationModal(true);
  };
  const handleBusinessVerificationModalClose = (): void => setShowBusinessVerificationModal(false);

  const handleUpdateVerification = (): void => {
    setStudioVerificationStage(StudioVerificationStage.Update);
    setShowUpdateVerificationModal(true);
  };
  const handleBeginVerifying = (): void => {
    setShowUpdateVerificationModal(false);
    setShowBusinessVerificationModal(true);
  };
  const handleUpdateVerificationModalClose = (): void => setShowUpdateVerificationModal(false);

  const handleMyInformationIsCorrect = (): unknown => dispatch(updateMyAccountStudioRequest({ id: studioId, reverified: true }));
  const handleCertifyUpdateInformation = (): void => {
    setStudioVerificationStage(StudioVerificationStage.Certification);
    setShowCertifyInformationModal(true);
  };
  const handleInformationHasChanged = (): void => {
    setShowCertifyInformationModal(false);
    setShowBusinessVerificationModal(true);
  };
  const handleCertifyInformationModalClose = (): void => setShowCertifyInformationModal(false);

  useEffect(() => {
    dispatch(getMyAccountStudioRequest({ studioId }));
  }, []);

  return (
    <>
      <header className="flex items-center justify-between my-studio__header">
        <h2 className="text-headline-sm">Verification</h2>
        {studioVerificationRequired && (
          <>
            {/* Get Verified */}
            {!studioVerificationAttributes?.new_verification_uid && studioVerificationStatus === StudioVerificationStatus.NotVerified && (
              <button className="button button--medium" type="button" name="verified" onClick={handleGetVerified}>
                Get Verified
              </button>
            )}

            {/* Verification in Progress */}
            {studioVerificationAttributes?.new_verification_uid && studioVerificationStatus !== StudioVerificationStatus.Verified && (
              <Tooltip
                {...{
                  title: 'Verification is in progress. Our customer success team will be in touch if there are any issues. Message us if you have questions!',
                  position: 'bottom',
                  size: 'big'
                }}
              >
                <button className="button button--blue-outline button--medium" type="button" name="progress" disabled>
                  Verificaton In Progress...
                </button>
              </Tooltip>
            )}

            {/* Update Information */}
            {studioVerificationAttributes?.new_verification_uid && studioVerificationStatus === StudioVerificationStatus.Verified && (
              <button className="button button--medium" type="button" name="update" onClick={handleUpdateVerification}>
                Update Verification
              </button>
            )}
          </>
        )}
      </header>
      {/* Certification */}
      {studioVerificationRequired &&
        moment().isSameOrAfter(studioVerificationAttributes?.certify_at, 'day') &&
        studioVerificationAttributes?.certification_status === StudioCertificationStatus.Uncertified && (
          <aside className="panel panel--light-yellow flex gap-2 items-center justify-between">
            <p className="text-body-lg m-0">
              Please review your {studioVerificationAttributes?.seller_type === StudioSellerType.Company && 'business'} information below. Have there been any
              changes in the last year?
            </p>
            <div className="flex justify-end gap-2.5">
              {studioVerificationAttributes?.certification_status === StudioCertificationStatus.Pending ? (
                <Tooltip
                  {...{
                    title:
                      'Certification is in progress. Our customer success team will be in touch if there are any issues. Message us if you have questions!',
                    position: 'bottom',
                    size: 'big'
                  }}
                >
                  <button className="button button--blue-outline button--medium" type="button" name="progress" disabled>
                    Certification In Progress...
                  </button>
                </Tooltip>
              ) : (
                <>
                  <button className="button button--small whitespace-nowrap" type="button" name="correct" onClick={handleMyInformationIsCorrect}>
                    My Information is Correct
                  </button>
                  <button className="button button--small whitespace-nowrap" type="button" name="update" onClick={handleCertifyUpdateInformation}>
                    Update Information
                  </button>
                </>
              )}
            </div>
          </aside>
        )}
      <p className="mb-10">
        As a high-volume seller, you must verify your business to continue selling with PhotoDay. This is to comply with the INFORM Consumers Act (ICA), which
        provides a national standard to ensure a safe online buying experience for consumers.{' '}
        <a href="https://support.photoday.io/en/articles/8828210-informed-consumers-act-ica-faq" target="_blank" rel="noopener noreferrer">
          Learn more
        </a>
      </p>

      {usersRequesting || !usersStudio?.id ? (
        <TableLoader rows={6} rowHeight={36} />
      ) : (
        <form>
          <h5 className="mb-5">Verification</h5>
          <fieldset className="flex gap-5">
            <div className="basis-2/6">
              <label>Status</label>
              <span className="text-body-sm block">{studioVerificationStatus === StudioVerificationStatus.Verified ? 'Verified' : 'Not Yet Verified'}</span>
            </div>
            <div className="basis-2/6">
              <label>Verified On</label>
              <span className="text-body-sm block">
                {studioVerificationStatus === StudioVerificationStatus.Verified &&
                  shortDate(studioVerificationAttributes.studioVerificationAttributes?.verified_at)}
              </span>
            </div>
          </fieldset>
          <h5 className="mb-5">Customer Facing Information</h5>
          <div className={studioVerificationStatus === StudioVerificationStatus.Verified ? '' : 'disabled'}>
            <fieldset className="flex gap-5">
              <div className="basis-2/6">
                <label htmlFor="verifiedAs">Verified As</label>
                <input
                  id="verifiedAs"
                  name="verifiedAs"
                  type="text"
                  value={
                    studioVerificationStatus === StudioVerificationStatus.Verified
                      ? studioVerificationAttributes?.seller_type === StudioSellerType.Company
                        ? 'Registered Company'
                        : 'Individual'
                      : ''
                  }
                  readOnly
                />
              </div>
            </fieldset>

            {/* Verify As Company */}
            {(studioVerificationStatus === StudioVerificationStatus.NotVerified || studioVerificationAttributes?.seller_type === StudioSellerType.Company) && (
              <fieldset className="flex gap-5 nowrap">
                <div className="basis-2/6">
                  <label htmlFor="businessLegalName">Business Legal Name</label>
                  <input id="businessLegalName" name="businessLegalName" type="text" value={studioVerificationAttributes?.business_name} readOnly />
                </div>
                <div className="basis-2/6">
                  <label htmlFor="doingBusinessAs">Doing Business As (Optional)</label>
                  <input id="doingBusinessAs" name="doingBusinessAs" type="text" value={studioVerificationAttributes?.dba} readOnly />
                </div>
              </fieldset>
            )}

            {/* Verify As Individual */}
            {studioVerificationAttributes?.seller_type === StudioSellerType.Individual && (
              <fieldset className="flex gap-5 nowrap">
                <div className="basis-2/6">
                  <label htmlFor="firstName">First Name</label>
                  <input id="firstName" name="firstName" type="text" value={studioVerificationAttributes?.first_name} readOnly />
                </div>
                <div className="basis-2/6">
                  <label htmlFor="lastName">Last Name</label>
                  <input id="lastName" name="lastName" type="text" value={studioVerificationAttributes?.last_name} readOnly />
                </div>
              </fieldset>
            )}

            {/* Customer Contact */}
            <fieldset className="flex gap-5 nowrap">
              <div className="basis-2/6">
                <label htmlFor="firstName">Customer Contact Email</label>
                <input id="contactEmail" name="contactEmail" type="email" value={studioContactEmail} readOnly />
              </div>
              <div className="basis-2/6">
                <label htmlFor="lastName">Customer Contact Phone</label>
                <PatternFormat id="contactPhone" name="contactPhone" format="+1 (###) ###-####" value={studioContactPhone} readOnly />
              </div>
            </fieldset>

            {/* Address */}
            <fieldset className="flex gap-5">
              <div className="basis-2/6">
                <label htmlFor="address">Address</label>
                <input id="address" name="address" type="text" value={usersStudio?.address?.line1} readOnly />
              </div>
              <div className="basis-2/6">
                <label htmlFor="aptUnit">Apt/Unit #</label>
                <input id="aptUnit" name="aptUnit" type="text" value={usersStudio?.address?.line2 ?? ''} readOnly />
              </div>
            </fieldset>
            <fieldset className="flex gap-5 nowrap">
              <div className="basis-2/6">
                <label htmlFor="city">City</label>
                <input id="city" name="city" type="text" value={usersStudio?.address?.city} readOnly />
              </div>
              <div className="basis-2/6">
                <label htmlFor="state">State</label>
                <input id="state" name="state" type="text" value={usersStudio?.address?.state} readOnly />
              </div>
              <div className="basis-2/6">
                <label htmlFor="zip">Zip</label>
                <input id="zip" name="zip" type="text" value={usersStudio?.address?.zip} readOnly />
              </div>
            </fieldset>
          </div>
        </form>
      )}

      {/* Update Verification Modal */}
      {showUpdateVerificationModal && (
        <aside className="modal animate">
          <div className="modal__box modal__box--small">
            <header className="modal__header">
              <button className="button button--action modal__close" name="close" type="button" onClick={handleUpdateVerificationModalClose}>
                <i className="icon-close"></i>
              </button>
            </header>
            <main className="modal__content text-left">
              <h1 className="text-headline-md">Update Verification</h1>
              <h6 className="text--normal">Updating your information will require you to go through the verification process again. Are you sure?</h6>
            </main>
            <footer className="modal__footer flex flex-wrap gap-4">
              <button className="button button--block" type="button" name="verifying" onClick={handleBeginVerifying}>
                Begin Verifying
              </button>
              <button className="button button--outline button--block" type="button" name="cancel" onClick={handleUpdateVerificationModalClose}>
                Cancel
              </button>
            </footer>
          </div>
        </aside>
      )}

      {/* Certify Information Modal */}
      {showCertifyInformationModal && (
        <aside className="modal animate">
          <div className="modal__box modal__box--small">
            <header className="modal__header">
              <button className="button button--action modal__close" name="close" type="button" onClick={handleCertifyInformationModalClose}>
                <i className="icon-close"></i>
              </button>
            </header>
            <main className="modal__content text-left">
              <h1 className="text-headline-md">Certify Information</h1>
              <h6 className="text--normal">
                Has any of your business information changed in the last year? If it has then you will have to reverify your business. If not there's nothing
                for you to do.
              </h6>
            </main>
            <footer className="modal__footer flex flex-wrap gap-4">
              <button className="button button--block" type="button" name="changed" onClick={handleInformationHasChanged}>
                Yes, my information has changed
              </button>
              <button className="button button--outline button--block" type="button" name="nothing" onClick={handleMyInformationIsCorrect}>
                No, nothing has changed
              </button>
            </footer>
          </div>
        </aside>
      )}

      {/* Persona Verification Modal */}
      {showBusinessVerificationModal && (
        <BusinessVerificationModal
          studioId={studioId}
          studioVerificationUid={studioVerificationAttributes?.new_verification_uid}
          studioVerificationStage={studioVerificationStage}
          onCloseVerificationModal={handleBusinessVerificationModalClose}
        />
      )}
    </>
  );
};

export default StudioVerification;

import { Lab } from '.';

export enum StudioRolesType {
  CustomRole = 'custom_role',
  CustomerService = 'customer_service'
}

export enum StudioVerificationStage {
  Verification = 'verification',
  Update = 'update',
  Certification = 'certification'
}

export enum StudioVerificationStatus {
  NotVerified = 'unverified',
  Pending = 'verification_pending',
  Verified = 'verified'
}

export enum StudioCertificationStatus {
  Uncertified = 'uncertified',
  Pending = 'certification_pending',
  Certified = 'certified'
}

export enum StudioSellerType {
  Individual = 'individual',
  Company = 'company'
}

export interface StudioAddress {
  id?: string;
  recipient?: string | null;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
  created_at?: string;
  updated_at?: string;
  meta?: Record<string, unknown>;
  skip_verification?: boolean;
}

export enum StudioStripeMigrationStatus {
  Unavailable = 'unavailable',
  Available = 'available',
  Approaching = 'approaching',
  Past = 'past',
  Complete = 'complete'
}

export enum StudioStripeOnboardingStatus {
  NotStarted = 'not_started',
  Incomplete = 'incomplete',
  Complete = 'complete'
}

export enum StudioStripeAccountType {
  Standard = 'standard',
  Express = 'express'
}

export interface Studio {
  id: string;
  name: string;
  image_url: string | null;
  web_url: string;
  phone: string;
  labs: Lab[];
  email: string;
  time_zone: string;
  reporting_code: string | null;
  studio_slug: string | null;
  watermark_filename: string;
  watermark_url: string;
  watermark_alpha: number;
  watermark_scale: number;
  logo_url: string | null;
  logo_filename: string | null;
  setup_backgrounds: boolean;
  created_at: string;
  updated_at: string;
  pd_fee: string;
  leaderboards_enabled: boolean;
  credit_cents: number;
  color_correction_cost_cents: number;
  knockout_cost_cents: number;
  watermark_position: string;
  display_capture_message: boolean;
  studio_credits_agreed: boolean;
  feature_flags: {
    school_beta: boolean;
    custom_themes: boolean;
    allow_custom_themes: string[];
    allow_backgrounds: string[];
    allow_knockouts: boolean;
    allow_retouches: boolean;
    allow_gallery_v4: boolean;
    allow_capture_qr: boolean;
    allow_studio_roles: boolean;
  };
  address: StudioAddress;
  secondary_address: StudioAddress;
  has_residential_address: boolean;

  verification_required: boolean;
  verification_status: StudioVerificationStatus;
  verification_deadline: Date | null;
  verification_mode: 'default' | 'ignore' | 'unsuspended';
  galleries_suspended: boolean;
  account_suspended: boolean;

  studio_verification: {
    studio_id: string;
    seller_type: StudioSellerType;
    first_name: string | null;
    last_name: string | null;
    business_name: string | null;
    dba: string;

    verification_uid: string;
    verified_at: Date;

    new_verification_uid: string;
    certify_at: Date;
    certified_at: Date;
    certification_status: StudioCertificationStatus;
  };

  stripe_user_id: string;
  stripe_account_type: StudioStripeAccountType;
  is_multi_studio: boolean;
  is_parent_studio: boolean;
  use_parents_stripe_account: boolean;
  resolved_stripe_migration_status: StudioStripeMigrationStatus;
  stripe_migration_deadline: Date | null;
  stripe_express_onboarding_status: StudioStripeOnboardingStatus | null;
}

import { FC, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  getMyAccountStudioRequest,
  updateMyAccountStudioRequest,
  deleteStripeAccountRequest,
  createStripeExpressOnboarding,
  createStripeExpressComplete,
  createStripeExpressInstantPayout,
  createStripeExpressReport,
  getStripeExpressInstantPayoutInfo,
  updateMyAccountStudioFromCable
} from '../../actions';

// Plugins
import DatePicker from 'react-datepicker';
import { useFlags, useFlagsmith } from 'flagsmith/react';
// @ts-ignore
import { useActionCable } from 'use-action-cable';

// Components
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';

// Helpers
import { getStripeAuthUri } from './Stripe/selectors';
import { formatCurrency } from '@/utils/displayFormats';
import removeTimeFromISO8601 from '@/utils/removeTimeFromISO8601';

// Images
import imageStripe from '@/assets/images/stripe-logo.svg';
import imageCheckmark from '@/assets/images/icon-checkmark.svg';
import imageStripeExpress from '@/assets/images/stripe-express.png';

// Types
import { StudioStripeOnboardingStatus, StudioStripeMigrationStatus, StudioStripeAccountType, StripeExpressPayoutReport } from '@/types';

const APP_STUDIO_URL: string = import.meta.env.VITE_STUDIO_URL;
const STRIPE_EXPRESS_REFRESH_URL: string = `${APP_STUDIO_URL}stripe/express/refresh`;
const STRIPE_EXPRESS_RETURN_URL: string = `${APP_STUDIO_URL}stripe/express/return`;

const StudioPayments: FC = () => {
  const dispatch = useDispatch();
  const location: { pathname: string; state: { expressCallbackStatus?: StudioStripeOnboardingStatus } } = useLocation();

  // Flags
  const flagsmith = useFlagsmith();
  const flags = useFlags(['stripe_express_payout_report']);
  const isPayoutReportFlagEnabled = flags?.stripe_express_payout_report?.enabled;

  const { studio: loginStudio } = useSelector((state: any) => state.login);
  const { studio: usersStudio, stripeExpressPayoutInfo: usersStripeExpressPayoutInfo, requesting: usersRequesting } = useSelector((state: any) => state.users);
  const {
    id: studioId,
    stripe_express_onboarding_status: studioStripeExpressStatus,
    stripe_user_id: stripeUserId,
    stripe_account_type: stripeAccountType,
    is_multi_studio: isMultiStudio,
    is_parent_studio: isParentStudio,
    resolved_stripe_migration_status: resolvedStripeMigrationStatus,
    use_parents_stripe_account: useParentsStripeAccount
  } = Object.keys(usersStudio ?? {}).length ? usersStudio : loginStudio;

  // State
  const [reportStartDate, setReportStartDate] = useState<Date | undefined>();
  const [reportEndDate, setReportEndDate] = useState<Date | undefined>();
  const [showEmailReportModal, setShowEmailReportModal] = useState<boolean>(false);
  const [showEmailReportSuccess, setShowEmailReportSuccess] = useState<boolean>(false);

  const [selectEmailReportType, setSelectEmailReportType] = useState<StripeExpressPayoutReport | null>(StripeExpressPayoutReport.Payout);
  const [showExpressPayOutModal, setShowExpressPayOutModal] = useState<boolean>(false);
  const [showExpressPayOutConfirmation, setShowExpressPayOutConfirmation] = useState<boolean>(false);

  // UI Handlers
  const handleParentStudioAccountChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const useParentStudioSwitch = event.currentTarget.checked ? true : false;
    dispatch(updateMyAccountStudioRequest({ id: studioId, use_parents_stripe_account: useParentStudioSwitch }));
  };

  const handleExpressMigrationSwitch = (): void => {
    dispatch(
      createStripeExpressOnboarding({ studioId, refresh_url: STRIPE_EXPRESS_REFRESH_URL, return_url: STRIPE_EXPRESS_RETURN_URL }, ({ data }: any) => {
        const { onboarding_url: onboardingUrl } = data;
        window.open(onboardingUrl, '_self');
      })
    );
  };

  const handleStripeStandardDisconnect = (): void => {
    dispatch(deleteStripeAccountRequest(studioId));
  };

  const handleStartDateChange = (date: Date | null) => setReportStartDate(date!);
  const handleEndDateChange = (date: Date | null) => setReportEndDate(date!);

  const handleToggleEmailReport = (): void => {
    if (!reportStartDate) setReportStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    if (!reportEndDate) setReportEndDate(new Date());

    setShowEmailReportSuccess(false);
    setShowEmailReportModal(!showEmailReportModal);
  };

  const handleEmailReport = (): void => {
    if (reportStartDate && reportEndDate)
      dispatch(
        createStripeExpressReport(
          {
            studioId,
            start_date: removeTimeFromISO8601(reportStartDate),
            end_date: removeTimeFromISO8601(reportEndDate),
            report_type: selectEmailReportType
          },
          () => setShowEmailReportSuccess(true)
        )
      );
  };

  // Express PayOut
  const handleExpressInitiatePayOut = (): void => {
    dispatch(
      getStripeExpressInstantPayoutInfo({ studioId }, () => {
        setShowExpressPayOutModal(true);
      })
    );
  };
  const handleExpressPayOut = (): void => {
    dispatch(
      createStripeExpressInstantPayout({ studioId, amount_cents: usersStripeExpressPayoutInfo.net_payout_cents }, () => {
        setShowExpressPayOutConfirmation(true);
      })
    );
  };
  const handleExpressPayOutCancel = (): void => {
    setShowExpressPayOutModal(false);
    setShowExpressPayOutConfirmation(false);
  };

  const handleMigrationMessageReceive = {
    received(message: any) {
      if (message.type === 'stripe_migration_status_updated') {
        dispatch(
          updateMyAccountStudioFromCable({
            resolved_stripe_migration_status: message.resolved_stripe_migration_status,
            stripe_migration_deadline: message.stripe_migration_deadline,
            stripe_express_onboarding_status: message.stripe_express_onboarding_status
          })
        );
      }
    }
  };

  useActionCable({ channel: 'StudioEventsChannel', studio_id: studioId }, handleMigrationMessageReceive);

  useEffect(() => {
    const { expressCallbackStatus } = location.state ?? {};

    if (expressCallbackStatus === StudioStripeOnboardingStatus.Complete) {
      dispatch(createStripeExpressComplete({ studioId }));
    } else {
      dispatch(getMyAccountStudioRequest({ studioId }));
    }

    // Indentify studio in flagsmith to overwrite flag values
    flagsmith.identify(studioId);
  }, []);

  return (
    <>
      <header className="flex items-center justify-between my-studio__header">
        <h2 className="text-headline-sm">Payment Settings</h2>
      </header>

      {usersRequesting && !usersStudio?.id ? (
        <GridLoader rows={3} columns={1} gap={20} minHeight={100} />
      ) : (
        <>
          {/* Multi-Studio */}
          {!isParentStudio && isMultiStudio && (
            <section className="flex justify-between flex-nowrap gap-5 pb-10 mb-10 border-b border-neutral-300">
              <figure className="shrink-0">
                <img src={imageStripeExpress} width="64" height="64" alt="Stripe Express" />
              </figure>
              <article className="grow">
                <h4>Use Parent Studio Stripe Account</h4>
                <p>
                  The Parent Studio for your account has already connected a Stripe account. Select this option if you don’t want to connect a separate Stripe
                  account.
                </p>
              </article>
              <input
                id="useParentsStripeAccount"
                className="hidden"
                name="useParentsStripeAccount"
                type="checkbox"
                checked={useParentsStripeAccount}
                onChange={handleParentStudioAccountChange}
              />
              <label
                htmlFor="useParentsStripeAccount"
                className={`label-switch ${usersRequesting ? 'label-switch--disabled' : ''}`}
                data-loading={usersRequesting}
              />
            </section>
          )}
          {/* Use Parent Studio Stripe Account */}
          {(isParentStudio || (!isParentStudio && !useParentsStripeAccount)) && (
            <div className="animate">
              {/* Switch to Stripe Express */}
              {studioStripeExpressStatus === StudioStripeOnboardingStatus.NotStarted && (
                <section className="flex justify-between flex-nowrap gap-5 pb-10 mb-2.5 border-b border-neutral-300">
                  <figure className="shrink-0">
                    <img src={imageStripeExpress} width="64" height="64" alt="Stripe Express" />
                  </figure>
                  <article>
                    <h4>Switch to Stripe Express</h4>
                    <p>
                      PhotoDay is migrating all users from Stripe Standard to Stripe Express. It’s a secure and easy way to receive the money you earn from
                      selling prints and products in PhotoDay.{' '}
                      <strong>Please create a new Stripe Express account before 12/31/25 to avoid any payment disruptions.</strong>
                    </p>
                  </article>
                  <button className="button button--large" type="button" name="switch" data-loading={usersRequesting} onClick={handleExpressMigrationSwitch}>
                    Make the Switch
                  </button>
                </section>
              )}
              {/* Switch to Stripe Express: Onboarding in Progress */}
              {studioStripeExpressStatus === StudioStripeOnboardingStatus.Incomplete && (
                <section className="flex justify-between flex-nowrap gap-5 pb-10 mb-10 border-b border-neutral-300">
                  <figure className="shrink-0">
                    <img src={imageStripeExpress} width="64" height="64" alt="Stripe Express" />
                  </figure>
                  <article>
                    <h4>Switch to Stripe Express: Onboarding in Progress</h4>
                    <p>
                      <strong>Finish switching to Stripe Express before 12/31/25!</strong> Click the button to resume setting up your account. It’s a secure and
                      easy way to receive the money you earn from selling prints and products in PhotoDay.
                    </p>
                  </article>
                  <button className="button button--large" type="button" name="resume" onClick={handleExpressMigrationSwitch}>
                    Resume Onboarding
                  </button>
                </section>
              )}
              {/* Stripe Express: Connected */}
              {studioStripeExpressStatus === StudioStripeOnboardingStatus.Complete && (
                <section className="flex justify-between flex-nowrap gap-5 pb-10 mb-10 border-b border-neutral-300">
                  <figure className="shrink-0">
                    <img src={imageStripeExpress} width="64" height="64" alt="Stripe Express" />
                  </figure>
                  <article>
                    <h4>Stripe Express: Connected</h4>
                    <p>
                      Receive the money you earn from selling prints and products in PhotoDay securely and easily with our payment processor, Stripe Express.
                      Proceeds from sales are automatically deposited into your bank account every Monday. Instant payout options are available.{' '}
                      <a href="https://support.photoday.io/en/articles/9701303-stripe-express" target="_blank" rel="noopener noreferrer">
                        Learn more
                      </a>
                    </p>
                  </article>
                  <aside className="flex flex-col gap-4">
                    <a className="button button--large" href="https://connect.stripe.com/express_login" target="_blank" rel="noopener noreferrer">
                      View Dashboard
                    </a>
                    <button
                      className="button button--blue-outline"
                      type="button"
                      name="initiate"
                      data-loading={usersRequesting}
                      onClick={handleExpressInitiatePayOut}
                    >
                      Initiate Instant Payout
                    </button>
                    {isPayoutReportFlagEnabled && (
                      <button
                        className="button button--blue-outline"
                        type="button"
                        name="emailReport"
                        data-loading={usersRequesting}
                        onClick={handleToggleEmailReport}
                      >
                        Email Report
                      </button>
                    )}
                  </aside>
                </section>
              )}

              {/* Stripe Standard Account */}
              {resolvedStripeMigrationStatus === StudioStripeMigrationStatus.Unavailable && !stripeUserId && (
                <section className="flex justify-between flex-nowrap gap-5">
                  <figure className="shrink-0">
                    <img src={imageStripe} width="64" height="64" alt="Stripe Express" />
                  </figure>
                  <article>
                    <h4>Stripe Standard Account</h4>
                    <p>
                      Stripe is a safe and easy way for you to accept credit card payments from around the world on prints that are being ordered. To start
                      receiving payments, please select the "Connect with Stripe" button below. Proceeds from any print sales will be automatically deposited
                      into your bank account within 7 days.
                    </p>
                  </article>
                  <a className="button button--large" href={getStripeAuthUri()} target="_blank">
                    Connect with STRIPE
                  </a>
                </section>
              )}
              {/* Stripe Standard Account: Connected */}
              {stripeAccountType === StudioStripeAccountType.Standard && stripeUserId && (
                <section className="flex justify-between flex-nowrap gap-5">
                  <figure className="shrink-0">
                    <img src={imageStripe} width="64" height="64" alt="Stripe Express" />
                  </figure>
                  <article>
                    <h4>Stripe Standard Account: Connected</h4>
                    {resolvedStripeMigrationStatus === StudioStripeMigrationStatus.Unavailable ? (
                      <p>Your stripe account is connected. Your store can now accept payments.</p>
                    ) : (
                      <p>
                        PhotoDay is migrating all users from Stripe Standard to Stripe Express. Make the switch before 12/31/25 to keep receiving the money you
                        earn from selling prints and products in PhotoDay.
                      </p>
                    )}
                  </article>
                  <aside className="flex flex-col gap-5">
                    <a className="button button--large" href="https://dashboard.stripe.com" target="_blank" rel="noopener noreferrer">
                      View STRIPE Dashboard
                    </a>
                    <button className="button button--danger-outline button--large" type="button" name="disconnect" onClick={handleStripeStandardDisconnect}>
                      Disconnect STRIPE
                    </button>
                  </aside>
                </section>
              )}
              {stripeAccountType === StudioStripeAccountType.Express && (
                <p>
                  Existing users looking for their Stripe standard account,{' '}
                  <a className="button button--link" href="https://dashboard.stripe.com" target="_blank" rel="noopener noreferrer">
                    click here
                  </a>
                  .
                </p>
              )}
            </div>
          )}
        </>
      )}

      {/* Payout Modal */}
      {showExpressPayOutModal && (
        <aside className="modal animate">
          <div className="modal__box modal__box--small">
            <main className="modal__content">
              {/* Payout */}
              {!showExpressPayOutConfirmation && (
                <div className="animate">
                  <h2 className="text-headline-sm">Initiate Instant Payout</h2>
                  <aside className="panel panel--gray mb-10">
                    <span className="font-headline font-semibold text-display-sm block mb-4">
                      ${formatCurrency(usersStripeExpressPayoutInfo.instant_available_cents)}
                    </span>
                    <span className="block">Available Balance for Instant Payout</span>
                  </aside>
                  <dl className="flex flex-col gap-4 mb-10">
                    <div className="flex justify-between mb-4">
                      <dt>
                        Instant Payout Fee ({usersStripeExpressPayoutInfo.fee_rate}%)
                        <br />
                        <span className="text-body-sm font-normal normal-case italic text-neutral-800">
                          *Fee is charged on the net money that will go to your account
                        </span>
                      </dt>
                      <dd className="font-bold">-${formatCurrency(usersStripeExpressPayoutInfo.fee_cents)}</dd>
                    </div>
                    <div className="flex justify-between">
                      <dt>Actual Payout Amount</dt>
                      <dd className="font-bold">${formatCurrency(usersStripeExpressPayoutInfo.net_payout_cents)}</dd>
                    </div>
                  </dl>
                  <footer className="flex justify-end gap-4">
                    <button className="button button--outline" type="button" name="cancel" onClick={handleExpressPayOutCancel}>
                      Cancel
                    </button>
                    <button
                      className="button"
                      type="button"
                      name="payout"
                      data-loading={usersRequesting}
                      disabled={usersStripeExpressPayoutInfo.instant_available_cents <= 0}
                      onClick={handleExpressPayOut}
                    >
                      Payout
                    </button>
                  </footer>
                </div>
              )}
              {/* Confirmation */}
              {showExpressPayOutConfirmation && (
                <div className="animate">
                  <h2 className="text-headline-sm mb-10">Instant Payout Initiated</h2>
                  <figure className="flex justify-center gap-4 mb-10">
                    <img src={imageCheckmark} width="38" height="38" alt="Confirmed" />
                    <figcaption className="body-sm text-left">
                      Payout in the amount of <strong>${formatCurrency(usersStripeExpressPayoutInfo.instant_available_cents)}</strong> will automatically be
                      disbursed to your bank account. This will incur a ${formatCurrency(usersStripeExpressPayoutInfo.fee_cents)} fee.
                    </figcaption>
                  </figure>
                  <footer className="flex justify-end">
                    <button className="button button--outline" type="button" name="close" onClick={handleExpressPayOutCancel}>
                      Close
                    </button>
                  </footer>
                </div>
              )}
            </main>
          </div>
        </aside>
      )}

      {/* Email Report Modal */}
      {showEmailReportModal && (
        <aside className="modal animate">
          <div className="modal__box modal__box--small">
            <main className="modal__content">
              {!showEmailReportSuccess && (
                <div className="animate">
                  <h2 className="text-headline-sm mb-4">Stripe Express Report</h2>
                  <div className="flex gap-2 items-center mb-8">
                    <DatePicker
                      className="input--date"
                      selected={reportStartDate}
                      onChange={handleStartDateChange}
                      selectsStart
                      startDate={reportStartDate}
                      endDate={reportEndDate}
                      maxDate={new Date()}
                      isClearable={false}
                      placeholderText="Start date"
                      strictParsing
                    />
                    <i className="icon-arrow-long-right"></i>
                    <DatePicker
                      className="input--date"
                      selected={reportEndDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      endDate={reportEndDate}
                      minDate={reportStartDate}
                      maxDate={new Date()}
                      isClearable={false}
                      placeholderText="End date"
                      strictParsing
                    />
                  </div>
                  <p className="font-bold">Select the type of report you would like to receive.</p>
                  <ul className="flex gap-4 mb-4">
                    <li className="flex flex-nowrap">
                      <input
                        id="payout"
                        type="radio"
                        name="type"
                        checked={selectEmailReportType === 'payout'}
                        onChange={() => setSelectEmailReportType(StripeExpressPayoutReport.Payout)}
                      />
                      <label htmlFor="payout" className="whitespace-pre-line">
                        Payout <small className="font-normal block">Money sent from your stripe account to an external account</small>
                      </label>
                    </li>
                    <li className="flex flex-nowrap">
                      <input
                        id="transaction"
                        type="radio"
                        name="type"
                        checked={selectEmailReportType === 'transaction'}
                        onChange={() => setSelectEmailReportType(StripeExpressPayoutReport.Transaction)}
                      />
                      <label htmlFor="transaction" className="whitespace-pre-line">
                        Transactions <small className="font-normal block">Money that was paid from PhotoDay to your stripe account</small>
                      </label>
                    </li>
                    <li className="flex flex-nowrap">
                      <input
                        id="reconciliation"
                        type="radio"
                        name="type"
                        checked={selectEmailReportType === 'reconciliation'}
                        onChange={() => setSelectEmailReportType(StripeExpressPayoutReport.Reconciliation)}
                      />
                      <label htmlFor="reconciliation" className="whitespace-pre-line">
                        Transfer Reconciliation <small className="font-normal block">List of transactions that were included in automatic payouts</small>
                      </label>
                    </li>
                  </ul>
                  <footer className="flex justify-end gap-4">
                    <button className="button button--outline" type="button" name="cancel" onClick={handleToggleEmailReport}>
                      Cancel
                    </button>
                    <button
                      className="button"
                      type="button"
                      data-loading={usersRequesting}
                      disabled={!reportStartDate || !reportEndDate}
                      onClick={handleEmailReport}
                    >
                      Email Report
                    </button>
                  </footer>
                </div>
              )}
              {showEmailReportSuccess && (
                <div className="animate flex flex-col items-center gap-4">
                  <h2 className="text-headline-sm">Hang Tight...</h2>
                  <p>
                    …and imagine the elevator music{' '}
                    <span role="img" aria-label="music notes">
                      🎶
                    </span>
                    . Your report request is processing and will arrive in your email inbox soon!
                  </p>
                  <footer>
                    <button className="button" name="gotit" onClick={handleToggleEmailReport}>
                      Got it!
                    </button>
                  </footer>
                </div>
              )}
            </main>
          </div>
        </aside>
      )}
    </>
  );
};

export default StudioPayments;
